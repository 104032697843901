<template>
  <div class="container">
    <img :src="info.cover" class="bg-img" />
    <div class="content pt-60 prow-30">
      <div class="title">{{ info.name }}</div>

      <div class="timer f-fs-c mt-30">
        <van-icon class="pr-10" size=".28rem" name="clock-o" />
        {{ info.eventStartDate }}
      </div>

      <Collapse :msg="info.brief" />

      <div v-for="(item, index) in info.activityDayListResponseList" :key="index" class="mt-30">
        <div class="item-box mt-10" @click="handleToDateInfo(item.name)">
          <div class="item-box mt-10">
            <div class="item-date f-c-c">{{ item.name }}</div>
            <img class="item-img" :src="item.cover" />
          </div>
        </div>
        <div class="blank"></div>
      </div>
    </div>

    <download-app :hidden="$route.query.hidden"/>

    <!-- <van-tabs
        class="active-tab mt-20"
        v-model="active"
        title-active-color="#87C6FF"
        title-inactive-color="#A3ABBF"
        animated
      >
        <van-tab
          v-for="(item, index) in info.timeList"
          :key="index"
          :title="item"
        >
          内容 {{ index }}
        </van-tab>
      </van-tabs> -->
  </div>
</template>

<script>
import { getItem } from '@/api/activity';
import Collapse from '@/components/Collapse.vue';
import DownloadApp from '@/components/DownloadApp.vue';

export default {
  name: 'ActivityIndex',

  filters: {
    showTimer: function (time) {
      return time.slice(0, 10);
    },
  },

  components: {
    Collapse,
    DownloadApp,
  },

  data: function () {
    return {
      id: '',

      info: {
        cover: '',
        name: '',
        eventStartDate: '',
        brief: '',
        dayList: [
          {
            date: '',
            url: '',
          },
        ],
      },

      active: null,
    };
  },

  computed: {},

  watch: {
    '$route.query': {
      handler(obj) {
        if (obj.id) {
          this.id = obj.id;
        }
      },
      immediate: true,
      deep: true,
    },
    id: {
      handler(str) {
        if (str) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleToDateInfo(date) {
      console.log(date);
      this.$success('查看成长记，需下载幼享乐App哟！～');

      // add by 梁展鹏 20210827 暂时不打开详情页。
      // this.$router.push({
      //   path: `/h5/activity/info?id=${this.id}&&time=${date}`
      // })
    },
    async loadData() {
      const { data } = await getItem({
        id: this.id,
      });

      this.info = data;
    },
  },
};
</script>

<style lang="scss">
.active-tab {
  height: 0.8rem;
  font-size: 0.28rem;
  .van-tabs__wrap {
    height: 0.8rem;
  }
  .van-tab--active .van-tab__text {
    font-size: 0.3rem;
    font-weight: bold;
  }
}
</style>

<style lang="scss" scoped>
.container {
  width: 100vw;
  background-color: #fff;
  position: relative;
  .bg-img {
    width: 100vw;
  }
  .content {
    box-sizing: border-box;
    position: relative;
    top: -0.4rem;
    width: 100vw;
    min-height: 0.4rem;
    background-color: #fff;
    border-radius: 0.3rem 0.3rem 0 0;
    .title {
      color: #0a1738;
      font-size: 0.4rem;
      font-weight: bold;
    }
    .timer {
      font-size: 0.24rem;
      color: #999;
    }
  }
  .item-box {
    position: relative;
    min-height: 1rem;
    .item-img {
      width: 100%;
      height: auto;
      border-radius: 0.2rem;
    }
    .item-date {
      position: absolute;
      min-height: 1.07rem;
      bottom: 0.1rem;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 0 0 0.2rem 0.2rem;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 0.3rem;
      font-weight: bold;
      letter-spacing: 0.02rem;
    }
  }
  .blank {
    width: 100%;
    min-height: 0.8rem;
  }
}
</style>
